.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-content {
  width: 45%;
  height: 100vh;
  background: #fff;
  transition: transform 1s ease-in-out;
  overflow-y: scroll;
  font-family: "Lexend Deca", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.02563rem;
  /* text-transform: capitalize; */
}

.modal-width{
  width: 65%;
}
.modal-width2{
  width: 60%;
}

.disabled_width{
  width: 100% !important;
}
.leftCreateClose{
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 55%;
}

.leftCreateClose2{
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 66%;
}

.close-icon {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  /* position: absolute; */
  top: 10px;
  right: 39rem;
  padding: 0.3rem 0.5rem;
  background: #ffffff;
  border-radius: 5px;
}

.create-lead-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dcdcdc;
}

.create-lead-top p {
  color: #1e2224;
  font-size: 1.375rem;
  font-weight: 500;
  font-family: "Lexend Deca", sans-serif;
}

.cross-icon {
  color: #1e2224;
}

/* form section  */

.create-lead-form {
  padding-left: 1rem;
  margin-top: 1.25rem;
}

.form-area {
  display: flex;
  flex-wrap: nowrap;
}

.form-section-1 .fa-plus {
  padding: 0.69rem;
  background-color: #f4f5f9;
  border-radius: 5rem;
  margin-left: 1.25rem;
  margin-bottom: 1rem;
}

/* .form-section-1 {
  float: left;
  margin-right: 1.5rem;
}

.form-section-2 {
  float: left;
} */

.lead-label {
  font-weight: 400;
  color: #1e2224; 
  font-size: 0.875rem;
  
  margin-bottom: 0.5rem;
  display: block;
}
.lead-label2 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #1e2224;
  margin-bottom: 0.5rem;
  font-family: "Lexend Deca", sans-serif;
}
.lead-input {
  color: #1e2224;
  width: 17rem;
  height: 2.25rem;
  font-weight: 400;
  outline: rgb(59, 59, 59);
  border-radius: 0.3125rem;
  border: 1px solid #c0c6cc;
  background: #fff;
  font-size: 14px;
  font-family: "Lexend Deca", sans-serif;
  padding: 0.8rem;
  margin-bottom: 1rem;
}
.lead-input:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.lead-input:focus {
  border: 1px solid rgb(127, 126, 126);
}
.lead-input2 {
  width: 17rem;
  height: 2.25rem;
  font-weight: 400;
}
.lead-input2:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.lead-input2:focus {
  border: 1px solid rgb(127, 126, 126);
}
.lead-priority{
  color: #1e2224;
  width: 17rem;
  height: 2.25rem;
  font-weight: 400;
  outline: rgb(59, 59, 59);
  border-radius: 0.3125rem;
  border: 1px solid #c0c6cc;
  background: #fff;
  font-size: 14px;
  font-family: "Lexend Deca", sans-serif;
  margin-bottom: 1rem; 
}
.lead-priority:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.lead-priority:focus {
  border: 1px solid rgb(127, 126, 126);
}
.phone-input-section {
  margin-bottom: 1rem;
}

.phone-input {
  color: #1e2224;
  height: 2.25rem;
  font-weight: 600;
  outline: rgb(59, 59, 59);
  border-radius: 0.3125rem;
  border: 1px solid #c0c6cc;
  background: #fff;
  width: 17rem;
  font-size: 14px;
  padding: 0.8rem;
}
.phone-input:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.phone-input:focus {
  border: 1px solid rgb(127, 126, 126);
}
.country-code {
  color: #1e2224;
  border-radius: 0.3125rem;
  border: 1px solid #c0c6cc;
  background: #fff;
  width: 3.8125rem;
  height: 2.25rem;
  padding-left: 0.32rem;
  margin-right: 0.62rem;
}

.currency-input {
  color: #1e2224;
  height: 2.25rem;
  width: 12.5rem;
  font-weight: 400;
  outline: rgb(59, 59, 59);
  border-radius: 0.3125rem;
  border: 1px solid #c0c6cc;
  background: #fff;
  font-size: 14px;
  font-family: "Lexend Deca", sans-serif;
  padding: 0.8rem;
}
.currency-input:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.currency-input:focus {
  border: 1px solid rgb(127, 126, 126);
}

.currency-section {
  display: flex;
  margin-bottom: 1rem;
}

.currency-section input {
  margin-right: 0.31rem;
}

.currency-value {
  color: #1e2224;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  border: 1px solid #c0c6cc;
  background: #fff;
  width: 4.3rem;
  height: 2.25rem;
  margin-right: 0.62rem;
  padding-left: 0.9rem;
  font-family: "Lexend Deca", sans-serif;
  
}

.select-owner {
  width: 17.5rem;
  border-radius: 0.3125rem;
  border: 1px solid #c0c6cc;
  background: #fff;
  height: 2.25rem;
  margin-bottom: 1.5rem;
}

/* lead status section  */

.lead-status p {
  font-size: 0.875rem;
  font-weight: 400;
  color: #1e2224;
  margin-bottom: 0.38rem;
  font-family: "Lexend Deca", sans-serif;
  
}

.status-value {
  display: flex;
  align-items: center;
  border-radius: 3.75rem;
  width: auto;
  height: 1.875rem;
  margin-left: 0.56rem;
}

.status-value span {
  padding: 0.62rem;
  padding-left: 0.31rem;
}

.elements {
  cursor: pointer;
  display: flex;
}

.new-element {
  margin-left: 0;
  background: #e0e0ff;
  color: #5181ff;
  border: 1px solid rgb(180, 183, 246);
  padding: 0 0.5rem;
  font-family: "Lexend Deca", sans-serif;
}
.new-element::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #5181ff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}
.open-element {
  border: 1px solid #b543eb;
  color: #b543eb;
  background-color: #efccff;
  padding: 0 0.5rem;
  font-family: "Lexend Deca", sans-serif;
}
.open-element::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #b543eb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}
.progress-element {
  border: 1px solid #63c257;
  color: #63c257;
  background-color: #cbffc5;
  padding: 0 0.5rem;
  font-family: "Lexend Deca", sans-serif;
}
.progress-element::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #63c257;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.deal-element {
  border: 1px solid #fd9802;
  color: #fd9802;
  background-color: #ffecd0;
  padding: 0 0.5rem;
  font-family: "Lexend Deca", sans-serif;
}
.deal-element::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #fd9802;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.unread-element {
  border: 1px solid #797979;
  color: #797979;
  background-color: #eaeaea;
  padding: 0 0.5rem;
  font-family: "Lexend Deca", sans-serif;
}
.unread-element::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #797979;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

/* bottom section  */

.form-section-2 {
  position: relative;
}

.bottom-section {
  width: auto;
  margin-left: -1rem;
  height: 4.125rem;
  background: #f8fafb;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.cancel-btn {
  margin-left: 1rem;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  background: #fff;
  color: #1e2224;
  font-family: "Lexend Deca", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.41px;
  text-transform: capitalize;
}
.cancel-btn:hover,
.add-btn:hover {
  cursor: pointer;
  border: 1px solid var(--secondary);
  background: var(--secondary);
  color: #ffffff;
  transition: all 400ms ease-in-out;
}

.add-btn {
  color: #1e2224;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  background: #fff;
  font-family: "Lexend Deca", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
}

.create-lead-btn {
  background: #077838;
  color: #fff;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: "Lexend Deca", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
  margin-right: 1rem;
  margin-left: 1rem;
}
.create-lead-btn:hover {
  cursor: pointer;
  color: #077838;
  background: #ffffff;
  border: 1px solid #077838;
  transition: all 400ms ease-in-out;
}

.selected-status{
  border:2px solid var(--ternary);
}