.homeHeader {
  display: flex;
}

.homeSecOne {
  border-right: 1px solid #dcdcdc;
  width: 21rem;
  height: 6.5rem;
  margin: 1rem 0;
  padding: 0.6rem 2rem;
}

.homeHeader section:last-child {
  border-right: 1px solid transparent;
}
.greyHead {
  display: flex;
  align-items: center;
}
.greyHead img {
  width: 1rem;
  margin-right: 0.5rem;
}
.fa-calendar,
.fa-phone {
  color: #909db0;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  margin-right: 0.5rem;
}
.home {
  color: #909db0;
  font-family: "Lexend Deca";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.homeNum {
  margin-right: 0.5rem;
  color: #1e2224;
  font-family: "Lexend Deca";
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}
.colorOval {
  color: #81c079;
  font-family: "Lexend Deca";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 2.5rem;
  background: #defdda;
}
.colorRedOval {
  color: #e76d56;
  font-family: "Lexend Deca";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 2.5rem;
  background: #fcd8d1;
}

.home-main-container {
  display: grid;
  grid-template-columns: 2fr auto;
  margin: 1rem;
}

.home-my-task {
  border-radius: 0.3125rem;
  background: #fff;
  margin-right: 0.1rem;
}

.my-task-heading {
  font-size: 1.375rem;
  font-weight: 500;
  margin-left: 1rem;
  margin-top: 1rem;
}

.home-my-task table {
  border: none;
}

.home-my-task td {
  border-left: none;
  border-right: none;
}

.home-open-task th {
  text-align: left;
  border: none;
  color: #9da0a3;
}

.home-today {
  display: flex;
  align-items: center;
  justify-content: start;
}

.home-today img {
  margin-right: 0.2rem;
}

.home-today-txt {
  color: #9da0a3;
  font-size: 0.875rem;
}

.home-profile-pic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-person-details {
  display: flex;
  justify-content: start;
  align-items: center;
}

.home-fox {
  margin-left: 0.5rem;
}

.home-profile-pic img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.home-open-task .home-table-value {
  border: 1px solid var(--border-color);
  padding: 1rem;
}

#home-table {
  border-spacing: 0px 1rem;
  border-collapse: separate;
}

.home-open-task tr td:first-child {
  border-left: 1px solid var(--border-color);
}
.home-open-task tr td:last-child {
  border-right: 1px solid var(--border-color);
}

.home-top {
  border: 1px solid var(--border-color);
  border-bottom: none;
}

.new-todo {
  margin-left: 0;
  background: #ffc9c9;
  color: #f66a6a;
  border: 1px solid #f66a6a;
  padding: 0 0.5rem;
  font-family: "Lexend Deca", sans-serif;
}
.new-todo::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #f66a6a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.home-right-container {
  margin-left: 0.5rem;
  border: 1px solid var(--border-color);
  text-align: center;
  height: calc(100% - 1rem);
}

.home-meetings {
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  font-size: 1.375rem;
  font-weight: 500;
}

.home-call {
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 0.3125rem;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 23rem;
}

.home-call-img {
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-meetings-time {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}

.home-time {
  margin-left: 0.5rem;
  width: 2.25rem;
  font-weight: 500;
  color: var(--font-color);
}

.home-meetings {
  font-weight: 500;
  font-weight: 0.875rem;
}

.time-icon {
  position: absolute;
  top: 80%;
  left: 5%;
}

/* dashboard css  */

.dashboard_header {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 5px -3px #e1d9d9;
  margin-top: 0.01rem;
  gap: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.dashboard_header2 {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #fff;
  gap: 1rem;
}

.select_new {
  cursor: pointer;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  color: #565c5f;
  font-size: 14px;
  font-weight: 500;
  font-family: "Lexend Deca", sans-serif;
  text-transform: capitalize;
  padding: 10px 15px;
  border-radius: 5px;
}

.dashboard_view {
  display: grid;
  grid-template-columns: 300px auto;
  height: 80vh;
}

.dashboard_left {
  border-right: 1px solid #dcdcdc;
}

.dashboard_left h2 {
  color: #222;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.dashboard_item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
  flex-wrap: wrap;
}

.font_new {
  color: #1e2224;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: none;
}

.dashboard_card h3 {
  margin-bottom: 0.6rem;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}

.dash_num {
  font-weight: 400;
  font-size: 1rem;
  color: #2b74da;
}

.leads_new_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 1rem;
}

.lead_input_box {
  width: 100%;
  margin-left: 1rem;
}

.lead_text_area textarea {
  height: 4rem;
}

.leads_new_tab {
  margin: 1rem;
}

.leads_table_height {
  height: 30rem;
  overflow: scroll;
}

.leads_new_tab table tr th:nth-child(7) {
  width: 20rem;
}
.leads_desc {
  height:1.6rem;
  overflow: scroll;
}
.redText,
.redText th,
.redText td{
  color: red;
}
/* dashboard css ends */

.lead_home{
  margin-top: 1rem;
}

.lead_home table td:nth-child(4){
  max-width:12rem;
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word;
}
.lead_home table td:nth-child(3){
  max-width:8rem;
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word;
}
.lead_home table td:nth-child(6){
  max-width:0.5rem;
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word;
}
.lead_home table td:nth-child(7){
  max-width:5.5rem;
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word;
}
.lead_home table td:nth-child(8){
  max-width:16rem;
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word;
}

.url_table{
  margin: 1rem;
  height: 65vh;
  overflow: auto;
}

.url_box{
  margin-top: 2rem;
}
.url_table th{
  text-align: left;
}
.url_table th:nth-child(2){
 width: 2rem;
}
.url_table th:nth-child(5){
 width:8rem;
}
.url_table th:nth-child(6){
 width:8rem;
}


.table_pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.page_number {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  background-color: #f0f0f0;
}

.page_number.active {
  font-weight: bold;
  background-color: #d0d0d0;
}

.page_number:hover {
  background-color: #e0e0e0;
}

.table_pagination button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.url-data-item p{
  font-size: 16px;
  margin-bottom: 0.8rem;
  color:#f49c00;
}

.url_text{
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.url-data-item p span{
 color:#222;
}

.url-data-item{
  margin: 1rem;
}

.url_input{
  width:98%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}


.url_modal_top{
  height: 90vh;
  overflow: auto;
}

.url_available{
  color:#08aa4e;
}
.url_unavailable{
 color:#f66a6a;
}

.red_border{
  border: 1px solid red;
}

.url_bottom_btn{
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: -1;
  margin-top: 1rem;
  margin-right: 1rem;

}

