.tourHead{
  margin-top: 0;
}
.tournamentRight{
  padding: 0rem 1rem;
width: 100%;
}
.tournamentInput{
  width: 100%;
  padding: 1rem;
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  background: #f3f6fa;
 
}

.tour_input{
  margin-top: 0.8rem;
}

.tour_upload{
  width:100%;
}

.tour-border{
  border-radius: 0.3125rem;
  border: 1px dashed #2b74da;
  background: #f3f6fa;
  padding: 1rem;
  height: 3rem;
  width: 100%;
}

.tour_new_file{
  display: flex;
  justify-content: start;
  align-items: center;
  height: 3.75rem;
}

.tour_upload_logo{
  margin-left: 1rem;
}

.tour_modal_input {
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  background: #f3f6fa;
  padding: 0.7rem;
  cursor: pointer;
  height: 2rem;
  width: 24rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.tour_modal_input_2{
  height: 3rem;
}

.tour_save{
  display: flex;
  justify-content: end;
  align-items: center;
}

.tour_save button{
  margin-right: 1rem;
  position: absolute;
  top:88%;
}

.tour_new_img {
  width:45.5%;
  height: 11.75rem;

}

.tour-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.tour-pagination-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tour-page-btn,
.tour-arrow-btn {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background: #fff;
  cursor: pointer;
  transition: background 0.3s;
}

.tour-page-btn.active {
  background: #c1daf5;
  color: #fff;
  border-color: #c1daf5;
}

.tour-arrow-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.tour-page-btn:hover,
.tour-arrow-btn:hover {
  background: #f1f1f1;
}

.tour-hidden-arrow {
  visibility: hidden;
}



.pagination-limit {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.limit-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin: 0;
}

.limit-select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: border 0.3s ease;
}

.limit-select:focus {
  border-color: #007bff;
  outline:none;
}

.dropdown-flex{
  display: flex;
  justify-content: start;
  align-items: start;
  gap:1rem;
}

.text-capitalize{
  text-transform: capitalize;
}

.tournament-modal-box{
  width:700px;
  padding: 1rem;
}
.tournament-modal-box p{
 font-size: 1.5rem;
 font-weight: 600;
 margin-bottom: 2rem;
}

.tournament-cross{
    right: 45rem;
    top: 0.94rem;
    cursor: pointer;
}

.tournament-list-box {
  background-color: #fff;
  box-shadow: 0 0 5px #dcdcdc;
  border-radius: 5px;
  margin-bottom: 1.2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}

.tournament-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Space between text elements */
}

.tournament-name {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  color: #007bff;
  cursor: pointer;
}

.tournament-name a {
  color: #007bff; /* Bootstrap link color */
  text-decoration: none;
}

.tournament-name a:hover {
  text-decoration: underline;
}

.tournament-details p {
  margin: 0.2rem 0;
  font-size: 0.9rem;
  color: #555;
}

.tournament-details strong {
  font-weight: bold;
}

.tournament-flex{
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap:1rem;
}

.tournament-url a{
  text-transform: lowercase;
}
