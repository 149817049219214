.accordion-body{
    height: 10rem;
    overflow-y: scroll;
    padding-bottom: 1rem;
}
.accordion-body-next{
  height: 10rem;
  overflow-y: scroll;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}
.coachFaqs-flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}
.coachFaqs-left{
    width: 90%;
}
.coachFaqs-leftPack{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deleteFaq{
    padding: 5px 12px;
    border-radius: 0.3125rem;
    border: 2px solid #FB5D52;
    background: #FFFFFF;
    color: #FB5D52;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: -0.02563rem;
}
.updateFaq{
  padding: 5px 12px;
  border-radius: 0.3125rem;
  border: 2px solid #077838;
  background: #FFFFFF;
  color: #077838;;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.02563rem;
}
.coachBtns{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.accordion-body2{
    height: 9rem;
    overflow-y: scroll;
    padding-bottom: 1rem;
}
.review_table{
    margin: 1rem;
    height: 65vh;
    overflow: auto;
  }
  
  .review_table th{
    text-align: left;
  }
  .review_table th:nth-child(1){
    width: 5rem;
   }
  .review_table th:nth-child(2){
   width: 15rem;
  }
  .review_table th:nth-child(3){
    width: 10rem;
   }
  .review_table th:nth-child(4){
    width: 10rem;
   }
   
.city-bottom-btn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  z-index: -1;
  margin-top: 2rem;
}
.package-input{
  width: 15rem !important;
}
.package-box{
  height: 5rem;
  overflow-y: scroll;
}
.viewsCount{
  font-size: 0.9rem !important;
  color: #2b74da !important;
}

.ticket_tbl{
  margin: 1rem;
  height: 65vh;
  overflow: auto;
}

.ticket_tbl th{
  text-align: left;
}
.ticket_tbl th:nth-child(1){
  width: 3rem;
 }
.ticket_tbl th:nth-child(2){
 width: 11rem;
}
.ticket_tbl th:nth-child(3){
  width: 7rem;
 }
.ticket_tbl th:nth-child(4){
  width: 10rem;
 }
 .ticket_tbl th:nth-child(6){
   width: 11rem;
  }
  .ticket_tbl th:nth-child(7){
    width: 11rem;
   }
 .ticket_des {
  height: 1.4rem;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
.bigReplies{
  height: 30vh;
  overflow-y: scroll;
}
.full-width{
  width: 100%;
}
.mailerHead{
  align-items: center;
}
.common-input2{
  width: 100%;
}
.user-details--right2 {
  width: 100%;
  padding: 1rem;
  height: 95vh;
  overflow-y: scroll;
}
.contact-tab-textarea2 {
  height: 6rem;
  outline: none;
  width: 100%;
  margin-bottom: 0.2rem;
}
.new-height{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 15px;
  position: relative;
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  
}
.editInput {
  color: #1e2224;
  font-weight: 400;
  outline: rgb(59, 59, 59);
  background-color: #ffffff;
  font-size: 0.8rem;
  font-family: "Lexend Deca", sans-serif;
  border-radius: 0.3125rem;
  padding: 0.3rem;
  border: 1px solid #dcdcdc;
  height: 2rem;
  width: 90%;
}
.mailer-sec{
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background: #f4f5f9;
}
.box1{
  width: 15%;
}
.box2{
  width: 35%;
}
.box3{
  width: 50%;
}
.p-width{
  width:8rem;
}
.f-width{
  width:6rem;
}
.mailer-conatiner{
  margin-bottom: 0.5rem;
  padding: 0;
  margin-right: 0.5rem;
}
.new-disable{
  background-color: #ccc !important;
  color: grey !important;
}
.mailer-conatiner2{
  margin-bottom: 1rem;
}
.reply-wrap{
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.attach-inp{
width: 15rem;
}
.overflowBind{
  max-width: 90%;
  overflow-x: scroll;
}
.shift-left{
  padding-left: 1rem;
}

.details>.quill {
  border-radius: 1rem;
  background: white;
}
.details>.quill>.ql-toolbar {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  background: white;
}
.details>.quill>.ql-container {
  background: white;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}
.editDetails>.quill {
  border-radius: 1rem;
  background: white;
}
.editDetails>.quill>.ql-toolbar{
  display: none;
}
.editDetails>.quill>.ql-container {
  border: none;
  height: 300px;
}

#coach_filter{
  cursor: pointer;
  padding: 7px 9px;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  color: rgb(30, 34, 36);
  font-weight: 500;
  border: 1px solid #dcdcdc;
  margin-left: 0.3rem;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: lightgrey;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
#subCatdown{
  margin-top:1rem;
  margin-bottom: 1rem;
}
#sportOptions {
  /* border: 2px solid red; */
  padding: 0.5rem 0.8rem;
}

.socialBox{
  margin-top: 180px;
}

.ql-container{
  height: 350px !important;
}