.addBlogContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
}
.addBlogMainForm {
  width: 63%;
}
.addBlogRightForm {
  width: 35%;
}
.fromFiled {
  padding-bottom: 30px;
}
.fromFiled input {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Lexend Deca";
  padding: 0.8rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}
.keywordsLower {
  text-transform: lowercase;
}
.from-view-img {
  padding-bottom: 30px;
}
.fromUrl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.fromUrl input {
  font-family: "Lexend Deca";
  height: 3rem;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Lexend Deca";
  padding: 0.8rem;
  margin-top: 0.3rem;
}
.fromUrl input:focus {
  border: 1px solid rgb(127, 126, 126);
}
.fromUrl input:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
/* .imageUploaderData{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
} */
.editImg {
  display: flex;
  gap: 1rem;
}
.imageUploaderData {
  background-color: #2b74da;
  color: #fff;
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14.5px;
  border-radius: 10px;
  border: none;
  /* width: 20rem; */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5); */
  transition: 0.2s linear all;
  margin: 0.5rem;
}

.imageUploaderData p {
  color: #2b74da;
  font-weight: 500;
  font-size: 14px;
}
.imageUploaderContainer {
  padding-bottom: 20px;
}

.from-blog-section input {
  margin-bottom: 1.5rem;
}
.fromBlogSection input:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.addSectionBtn {
  color: #2b74da;
  background-color: #fff;
  cursor: pointer;
  width: 10rem;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  height: 3rem;
  border: none;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5); */
}
.formBtnBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.tagData {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1rem 1rem 1rem;
}
.tagItems {
  margin-right: 1rem;
  padding: 0.3rem 0.3rem;
  font-weight: 600;
  background-color: #88a8d5;
  text-align: center;
  border-radius: 5px;
  color: #fff;
}
.tagItems button {
  background-color: #88a8d5;
  border: none;
  color: #fff;
  padding: 0 0.5rem;
  cursor: pointer;
}

.tags {
  background: #f8fafb;
  margin-bottom: 1rem;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.02);
  /* box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.2); */
}

.tags h3 {
  font-size: 20px;
  font-family: "Lexend Deca", sans-serif;
  color: #1e2224;
  padding: 14px;
  border-bottom: 1px solid #dcdcdc;
}

.contentBox {
  display: flex;
  padding: 1.5rem 1rem;
  gap: 1rem;
}

.tagSelectBox {
  font-size: 17px;
  font-family: "Lexend Deca", sans-serif;
  color: rgb(30, 34, 36);
  height: 43px;
  background-color: #f3f6fa;
  font-weight: 500;
}

.contentBox select {
  width: 50%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  outline: rgb(59, 59, 59);
  font-size: 16px;
  font-family: "Lexend Deca";
}

.new_tag {
  height: 43px;
  border-radius: 5px;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  color: rgb(30, 34, 36);
  font-weight: 500;
  border: 1px solid #dcdcdc;
  background-color: #f3f6fa;
}
.contentBox select:focus {
  border: 1px solid rgb(127, 126, 126);
}
.contentBox select:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.contentBox select option {
  font-size: 16px;
  font-family: "Lexend Deca";
}

.contentBox .fromFiled {
  width: 100%;
}
.contentBox input {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Lexend Deca";
  padding: 1rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}

.primaryBtn {
  cursor: pointer;
  padding: 10px;
  font-size: 17px;
  font-family: "Lexend Deca", sans-serif;
  border-radius: 10px;
  border: 1px solid #2b74da;
  background: #ffffff;
  color: #2b74da;
  font-weight: 500;
}

.primaryBtn:hover {
  color: #ffffff;
  background: #2b74da;
  transition: all 800ms ease-in-out;
}

.secondaryBtn {
  cursor: pointer;
  padding: 10px;
  font-size: 17px;
  font-family: "Lexend Deca", sans-serif;
  border-radius: 10px;
  border: 1px solid #077838;
  background: #077838;
  color: #ffffff;
  font-weight: 500;
}

.secondaryBtn:hover {
  color: #077838;
  background: #ffffff;
  border: 1px solid #077838;
  transition: all 800ms ease-in-out;
}
.formEditor {
  height: 14rem;
}
.quillEditor {
  height: 9.3rem;
  z-index: 0;
}

.imageHolder {
  width: 3rem;
  height: 3rem;
}

.addSection {
  height: 35rem;
  margin-bottom: 1rem;
}
.section {
  margin-bottom: 1rem;
  border: 1px solid #dcdcdc;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 10px;
}
.sectionDropdown {
  border: 2px solid red;
  border: 1px solid #dcdcdc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  text-transform: capitalize;
  color: #385083;
  background-color: transparent;

  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}
.sectionHead {
  width: 50%;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Lexend Deca";
  padding: 0.8rem;
  margin: 0.8rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}
.sectionSort {
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Lexend Deca";
  padding: 0.8rem;
  margin: 0.8rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}
.SubsectionSort {
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Lexend Deca";
  padding: 0.8rem;
  width: 3.5rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}
.sectionBlockOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}
.deleteContainer {
  display: flex;
  justify-content: right;
}
.sectionDelete {
  cursor: pointer;
  background-color: #fff;
  border: none;
  padding: 0 1rem;
}
.deleteIcon {
  width: 1.5rem;
  margin: 0rem 1rem;
}
.answer {
  /* background-color:#2b74da; */
  display: none;
}
.display_answer {
  display: block;
}
.image {
  font-size: 1rem;
  margin: 0.2rem;
}
.SiteSelectBox {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  outline: rgb(59, 59, 59);
  font-size: 16px;
  font-family: "Lexend Deca";
  padding: 0.5rem;
}
@media (max-width: 800px) {
  .addBlogContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .addBlogMainForm {
    width: 90%;
  }
  .addBlogRightForm {
    width: 90%;
  }
  .fromUrl {
    display: flex;
    flex-wrap: wrap;
  }
}
.updateMsg {
  color: red;
  font-size: 15px;
  font-family: "Lexend Deca";
  text-transform: capitalize;
  min-width: 150px;
  font-weight: 600;
  text-align: center;
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.blogImageEdit {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.editBox {
  display: flex;
  flex-direction: column;
}
.imageContainer {
  position: relative;
  display: inline-block;
}

.imageHoverText {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  border-radius: 5px;
}
.docUpImg {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  margin: 0.5rem;
}
.accHead {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.blog-update-btn {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 85%;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}

.blog-disable {
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2rem;
  margin-top: 1.2rem;
  margin-left: 0.5rem;
}

.blog-img {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.blog-browse-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -1rem;
}
.blog-browse-img-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-img-2 {
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  border-radius: 10px;
  height: 3rem;
  border: none;
  margin-left: 1rem;
  width: 10rem;
}

.blog-update-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.blog-add-img {
  background-color: #2b74da;
  color: white;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  border: 1px solid transparent;
  width: 10rem;
}
.blog-add-img:hover {
  background-color: white;
  border: 1px solid #2b74da;
  color: #2b74da;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  transition: all 0.5s ease-in-out;
}

.blog-img-2 {
  margin-right: 0.5rem;
}

.update-img {
  width: 16rem;
  margin-left: 0;
}

.section-img-new {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.from-filed {
  padding-bottom: 30px;
}

.from-filed input,
.from-filed-2 input {
  border-radius: 0.3125rem;
  border: 1px solid var(--Stroke, #dcdcdc);
  background: var(--Selected-State, #f3f6fa);
  width: 100%;
  padding: 1rem;
  margin-top: 0.3rem;
}

.from-filed input::placeholder,
.from-filed-2 input::placeholder {
  color: #1e2224;
  font-family: Lexend Deca;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02563rem;
}

.blogs-new-label {
  color: #818181;
}

.blog-url-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}
.blog-url-input-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.new_blog_flex{
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.blog-sort input {
  width: 13rem;
}

.add-img-3 {
  width: 12.5rem;
}

.blog-new-img {
  margin-left: 1rem;
  margin-right: 1rem;
}

.add-new-blog {
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
}

.tag-box {
  background-color: #fff;
  border: 1px solid #dcdcdc;
}
.tag-box>h3{
  font-size: 1rem;
}

.tag-box-1 {
  border-bottom: 1px solid transparent;
}
.tag-box-2 {
  border-top: 1px solid transparent;
}

.blog-add-date input {
  height: 43px;
  width: 100%;
}

.blog-save-height {
  height: 43px;
  width: 100%;
  padding: 0 !important;
}

.first-section {
  margin-top: 7.5rem;
}

.add_link_top_btn {
  margin-top: 1rem;
}

.blog-cross {
  margin-left: 0.2rem;
}

.blog-publish {
  padding: 0 !important;
}

.blog-back {
  margin-left: 1rem;
}

.update-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update-view p:last-child {
  margin-right: 1rem;
}

.row_name {
  display: flex;
  align-items: center;
}
.dynamic-input {
  width: 10rem;
}
.row_label {
  display: inline-block;
  width: 5rem;
  margin-bottom: 1rem;
}

.dynamic_table {
  border-color: green;
}

.dynamic-input {
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  background: #f3f6fa;
  padding: 0.7rem;
  cursor: pointer;
  width: 8.5rem;
  height: 2rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.dynamic-box {
  width: 60rem;
}

.box_2 {
  margin-left: 1rem;
}

.hhh {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  height: 0.5rem;
}

.blog_dynamic_table {
  display: flex;
  gap: 10rem;
  align-items: center;
}

.btn_flex_new {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 1.5rem;
}

.dropdown_newtag {
  position: absolute;
  top: 2.7rem;
  left: -4.6rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 12rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 55vh;
}

.dropdown_newtag ul li {
  padding: 10px;
  cursor: pointer;
  padding-left: 15px;
}

.dropdown_newtag ul {
  height: 40vh;
  overflow: scroll;
  overflow-x: hidden;
}

.dropdown_newtag ul li:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
}

.tag_new_li {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 0.3rem;
  margin-left: -0.8rem;
}

.new_tags_btn {
  padding-top: 0.8rem;
  border-top: 1px solid #dcdcdc;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.new_tags_btn button {
  margin-left: 1rem;
}

.jodit-wysiwyg p a {
  text-decoration: underline;
  color: blue;
  text-transform: lowercase;
}

.key_url_box {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40vh;
  overflow: scroll;
}

.key_url {
  margin-bottom: 1rem;
}

.data_keyowrd,
.orange_color {
  color: #f49c00;
}

.new_add_link {
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
  width: 100%;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.jodit-workplace {
  min-height: 0 !important;
  overflow: hidden !important;
}

.jodit-wysiwyg {
  min-height: 0 !important;
  height: 10rem !important;
  overflow: scroll !important;
}

.jodit-wysiwyg p {
  font-size: 0.875rem !important;
}

/* back list css  */

.backlist_table{
  padding: 1rem;
  height: 80vh;
  overflow: scroll;
}

.backlist_table table th{
  background-color: #f3f6fa;
  text-align: left;
}

.backlist_table table td:nth-child(1),
.backlist_table table td:nth-child(2),
.backlist_table table td:nth-child(3){
  border-right: 1px solid transparent;
}

.backlist_table table td{
  text-align: left;
}
.backlist_table table td:nth-child(2){
  color:#2b74da;
}
/* back list css ends */

.form_group_blog {
  position: relative;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

.browse_new_img{
  margin-bottom: 1.6rem;
}

.form_group_blog:focus-within {
  border-color: #007bff;
}

.form_input_blog:focus{
  border: none;
  box-shadow: none;
  padding: 1rem;
}

.form_label_blog {
  left: 10px;
  padding:5px;
  pointer-events: none;
  background-color: #fff;
  position: absolute;
  top: 10px;
  transition: 0.5s;
  transform-origin: left top;
  color: #999;
}

.form_input_blog:focus + .form_label_blog,
.form_input_blog:not(:placeholder-shown) + .form_label_blog {
  transform: scale(0.8) translateY(-30px);
  color: #007bff;
}

.new_blog_input {
  height: 2rem;
  width: 100%;
  font-size: 0.8rem;
  background: 0 0;
  padding:1rem;
  font-weight: 400;
  /* text-transform: capitalize; */
  outline: 0;
  cursor: pointer;
  color: #222 !important;
  border: none;
  margin-top: 0.5rem;
}

