.password-heading{
font-size: var(--font-big);
font-style: normal;
font-weight: 600;
margin-bottom: 1.2rem;
margin-top: 2rem;
}

.password-rules {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 1rem;
}

.password-rules > div {
    display: flex;
    align-items: center;
}

.password-text {
    color: var(--font-light);
}

.password-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
}


.password-checkbox .checkmark {
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--checkbox-background);
    border: 1px solid transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-checkbox input:checked ~ .checkmark {
    background-color: var(--primary);
}

.password-checkbox .checkmark:before {
    content: "✓";
    color: var(--white);
}

.password-text {
    margin: 0;
    flex: 1; /* Add this to make the text take the available space */
    margin-left: 0.5rem;
    width: 25rem;
}

.password-input {
    width: 3.375rem;
    background-color: #fff;
    text-align: center;
    height: 1.5rem;
}

.password-input::-webkit-outer-spin-button,
.password-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: none;
}


.password-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.password-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.password-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.password-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.password-slider {
    background-color: #33c75a;
}

input:focus+.password-slider {
    box-shadow: 0 0 1px #33c75a;
}

input:checked+.password-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.password-slider.password-round {
    border-radius: 34px;
}

.password-slider.password-round:before {
    border-radius: 50%;
}

.password-authentication {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 2.94rem;
    margin-bottom: 0.94rem;
}

.password-two-factor {
    font-size: 1.125rem;
    font-weight: 600;
    margin-right: 0.5rem;
}

.password-bottom{
    color:#33475B;
font-size: 0.75rem;
margin-bottom: 2rem;

}

.password-toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    padding: 1rem;
    border-radius: 0.3125rem;
    margin-top: 2rem;
}

.password-lock{
    display: flex;
    align-items: center;
    justify-content: start;
}

.password-lock img{
    margin-right: 1.25rem;
}


.password-policy{
font-size: var(--font-medium);
}

.password-guideline{
    font-size: var(--font-small);
    color: var(--font-light);
}


.password-bottom-btn{
    margin-top: 2rem;
    background-color: var(--th-background);
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.password-save{
    margin-left: 1rem;
}

/* Adjusting the .lead-switch dimensions to be smaller */
.lead-switch {
    width: 40px; /* Smaller width */
    height: 22px; /* Smaller height */
}

/* Smaller slider within the .lead-switch */
.lead-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 22px; /* Ensuring rounded edges for smaller slider */
}

.lead-slider:before {
    position: absolute;
    content: "";
    height: 18px; /* Smaller knob height */
    width: 18px; /* Smaller knob width */
    left: 2px; /* Adjusted for smaller size */
    bottom: 2px; /* Adjusted for smaller size */
    background-color: white;
    transition: .4s;
    border-radius: 50%; /* Rounded knob for smaller slider */
}

input:checked + .lead-slider {
    background-color: #33c75a;
}

input:checked + .lead-slider:before {
    transform: translateX(18px); /* Smaller shift distance to accommodate smaller size */
}

/* Adding focus style for better accessibility */
input:focus + .lead-slider {
    box-shadow: 0 0 1px #33c75a;
}
