.leadDetails-section{
    padding: 0rem 0.8rem;
}
.leadDetails-expand{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
}
.lead-description{
    padding:0rem 2rem 1rem 2rem;
}
.leads-details{
color: #1e2224;
    font-family: "Lexend Deca", sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.02563rem;
    margin-top: 0.6rem;
    padding-bottom: 0.6rem;
    text-align: left;
}
.about-textarea{
    margin-bottom:4rem !important;
}
.sportCap{
    text-transform: capitalize;
}
.divWidth{
    max-width: 55vw !important;
}
#academyDetHead{
    text-align: left;
    font-family: "Lexend Deca";
    font-size: 1.125rem;
    font-weight: 500;
    color: #1e2224;
    width: 40rem;
    border: none;
}
.headingDet{
    margin-bottom: 0;
    margin-left: 1rem;
    line-height: 0;
}
.logoRound{
    border-radius: 50%;
}
.newSectionCss{
    padding: 1rem;
    margin-bottom: 0.5rem;
}
.logsHeight{
    max-height: 80vh;
}
.logDivs{
margin-bottom: 0.7rem;
}
.logHeading{
    font-weight: 700;
    font-size: 0.8rem;
 color: #2b74da;;
}

.logsHeight table th{
    background-color: #f4f5f9;
    text-align: left;
}


.logsHeight table td:nth-child(1),
.logsHeight table td:nth-child(2),
.logsHeight table td:nth-child(3),
.logsHeight table td:nth-child(4),
.logsHeight table td:nth-child(5),
.logsHeight table td:nth-child(6),
.logsHeight table td:nth-child(7),
.logsHeight table td:nth-child(8){
  border-right: 1px solid transparent;
}
.compressEmail{
    width: 10rem !important;
}
.newEditableArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}
.radio-space{
    width: 15rem;
    margin-right: 0;
}
.button-left{
    margin-left: 1rem;
}