.lead-body {
  padding: 8px 30px;
  border-bottom: 1px solid #dcdcdc;
}

.top-head {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.left-side--btns,
.right-side--btns {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap:8px;
}

.right-side--btns{
  margin-left: 0.5rem;
}



.view {
  margin-left: 0rem;
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.1),
    -2px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #ffffff; /* replace with your desired background color */
}

.grid-view--btn,
.list-view--btn {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 9px;
  border-radius: 5px;
  font-size: 14px;
  /* margin: 0 0.3rem; */
}

.view .active-btn {
  border: 1px solid #2b74da;
  color: #2b74da;
}

.list-view--btn {
  border: 1px solid #ffffff;
}

.list-view--btn:hover,
.settings:hover {
  border: 1px solid #2b74da;
  color: #2b74da;
}

.right-side--btns p {
  color: #1e2224;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 0.875rem;
}

.right-side--btns > .secondary-btn {
  padding: 8px 20px;
  font-family: "Lexend Deca", sans-serif;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  border: 1px solid #077838;
  background: #077838;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}
.secondary-btn:hover {
  cursor: pointer;
  color: #077838;
  background: #ffffff;
  border: 1px solid #077838;
  transition: all 400ms ease-in-out;
}

.simple-btn {
  padding: 7px 25px;
  font-size: 0.875rem;
  font-family: "Lexend Deca", sans-serif;
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  color: #1e2224;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.simple-btn:hover {
  border: 1px solid #077838;
  background: #077838;
  color: #ffffff;
  transition: all 400ms ease-in-out;
}
.dropdown-container {
  position: relative;
  display: inline-block;
  margin: 0;
  height: 2.125rem;
  font-family: "Lexend Deca", sans-serif;
  text-transform: capitalize;
  border-radius: 0.3125rem;

}

.importDiv {
  margin-left: 2rem;
}
.dropdown-header {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  color: rgb(30, 34, 36);
  border: 1px solid #699ce5;
  font-weight: 500;
  background: #eff6ff;
}
.dropdown-header2 {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  color: rgb(30, 34, 36);
  font-weight: 500;
  border: 1px solid #dcdcdc;
}
.dropdown-header3 {
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  color: rgb(30, 34, 36);
  font-weight: 500;
  border: 1px solid #dcdcdc;
}
.dropdown-menu {
  position: absolute;
  top: 2.7rem;
  left: -4.6rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 12rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.pipelineMenu {
  position: absolute;
  top: 2.7rem;
  left: -1.5rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 12rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.dropdown-menuLead {
  position: absolute;
  left: 0rem;
  top: 2.7rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 10rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.dropdown-menu li,
.pipelineMenu li,
.dropdown-menuLead li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
}
.pipelineMenu li:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
}
.cards-body {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.cards-body::-webkit-scrollbar {
  width: 5px; /* Adjust the width as desired */
}
.card-details {
  position: relative;
}
.main-cards {
  min-width: 17.6875rem;
  height: 33rem;
  border: 1px solid #dcdcdc;
  padding: 35px 6px;
  background: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main-cards::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-cards {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.cards-new {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #f4f5f9;
  border: 1px solid #dcdcdc;
  padding: 10px 20px;
  font-family: "Lexend Deca", sans-serif;
  text-transform: capitalize;
  margin-bottom: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.cards-new .new-leads::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #5181ff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}
.new-leads {
  color: #5181ff;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Lexend Deca", sans-serif;
}
.new-color {
  color: #5181ff;
}
.cards-new .new-leads::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #5181ff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.open-color {
  color: #b543eb;
}
.cards-new .open-color::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #b543eb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.progress-color {
  color: #63c257;
}
.cards-new .progress-color::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #63c257;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.deal-color {
  color: #fd9802;
}
.cards-new .deal-color::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #fd9802;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.unread-color {
  color: #797979;
}
.cards-new .unread-color::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #797979;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  vertical-align: 3%;
}

.cards-new .leads {
  font-size: 0.875rem;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  color: #33475b;
}
/* css for check box */
/* Hide the default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/* Style the custom checkbox */
.custom-checkbox .checkmark {
  position: relative;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #dcdcdc;
  background-color: transparent;
  border-radius: 0.125rem;
  cursor: pointer;
}

/* Create the tick mark using the :after pseudo-element */
.custom-checkbox .checkmark:after {
  content: "✓";
  /* Unicode character for a checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the tick mark */
  font-size: 14px;
  color: #2b74da;
  opacity: 0;
}

.custom-checkbox input:checked ~ .checkmark:after {
  opacity: 1;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #f3f6fa;
  border: 1px solid #2b74da;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  margin: 0px !important;
}

.user-card {
  cursor: pointer;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 0.3125rem;
  width: 16.6875rem;
  height: 6.875rem;
  margin: 8px 0px;
}
.user-card:hover {
  background-color: #f4f5f9;
}
.user-card2 {
  cursor: pointer;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 0.3125rem;
  width: 16.6875rem;
  margin: 8px 0px;
}
.user-card2:hover {
  background-color: #f4f5f9;
}
.card-container {
  display: flex;
  justify-content: space-between;
}
.card-rightBox {
  text-align: center;
}
.user-details {
  margin-bottom: 0.5rem;
}
.lead-value {
  color: #1e2224;
  font-family: "Lexend Deca";
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.user-details img {
  width: 0.75rem;
  height: 0.75rem;
}

.user-details p {
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 22px;
}

.city_cards p{
  color:#f49c00;
}

.user-details p span {
  font-size: 0.7rem;
  font-weight: 300;
  color: #818181;
  font-family: "Lexend Deca", sans-serif;
}

.user-setting--btn {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 0.5px 3px;
  border-radius: 5px;
  font-size: 18px;
  max-height: 30px;
  color: #1e2224;
  position: relative;
  display: inline-block;
  margin-bottom: 0.2rem;
}

.user-setting--btn:hover {
  border: 1px solid #2b74da;
  color: #2b74da;
}
.cardMenu {
  position: absolute;
  top: 2.2rem;
  right: -1rem;
  margin: 0;
  list-style-type: none;
  padding: 10px 7px;
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 3;
}
.cardMenu li {
  width: 8rem;
  padding: 0.2rem;
  text-align: left;
  color: #33475b;
  font-family: "Lexend Deca";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}
.cardMenu li:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
}
.contact-details p {
  font-size: 0.87rem;
  color: #818181;
  font-weight: 500;
}
.details_flex{
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap:1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 0.5rem;
}

.no-border {
  border-bottom: none;
}
.right_width{
  white-space: nowrap;
}
.mail,
.call {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.5rem;
}

.mail img,
.call img {
  width: 0.875rem;
  height: 0.875rem;
}
.fa-ellipsis-h {
  font-weight: 600;
}
.priorityBox {
  margin-bottom: 0.2rem;
  width: fit-content;
}
.priorityBox > .Avg {
  padding: 2px 4px;
  /* font-size: 8px; */
}
.leads-priority {
  color: #ffffff !important;
  font-size: 0.75rem;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  padding: 4px 6px;
  border-radius: 5px;
  text-transform: capitalize;
}

.bottom-fixed {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  height:2.5rem;
  /* border-radius: 0 0 10px 10px; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.flexBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.extraFlex{
  margin-bottom: 0.2rem;
}
.bottom-fixed p {
  font-size: 1rem;
  color: #1e2224;
  font-weight: 500;
  text-transform: capitalize;
  float: right;
}

/* modal box code */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.customization_popup_container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  background: #fff;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: nowrap;
  width: 60%;
}
.leftClose{
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 40%;
}
.close {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  position: absolute;
  top: 8px;
  left: -40px;
  padding: 0.3rem 0.5rem;
  background: #ffffff;
  border-radius: 5px;
}
/* basic modal box code ends here */
/* modal box content */
.user-details--left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  /* min-width: 350px; */
  /* border-right: 1px solid #dcdcdc; */
  padding: 15px;
  position: relative;
  height: 90vh;
  overflow-y: scroll;
}

.user-details--heading {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.user-details--heading img {
  width: 3.125rem;
  height: 3.125rem;
}

.user-details--heading p {
  text-align: left;
  /* text-transform: capitalize; */
  font-family: "Lexend Deca";
  font-size: 1.125rem;
  font-weight: 500;
  color: #1e2224;
  line-height: 17px;
}

.user-details--heading p span {
  color: #818181;
  font-family: "Lexend Deca";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02563rem;
}
.user-details-imgBox {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.user-details-imgBox input {
  color: #1e2224;
  font-weight: 400;
  border: 1px solid #dcdcdc;
  outline: rgb(59, 59, 59);
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 12px;
  font-family: "Lexend Deca";
  padding: 0.5rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}
.user-details-imgBox input:focus {
  border: 1px solid #e2e9f2;
}
.leadDetailsLeft {
  overflow: hidden;
  overflow-y: scroll;
}
.detailsBox {
  padding-top: 0.7rem;
  text-align: left;
}
.detailHead {
  color: #1e2224;
  font-family: "Lexend Deca";
  font-size: 0.82rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02563rem;
  margin-bottom: 0.8rem;
}
.detailsContent {
  display: grid;
  grid-template-columns:auto 1fr;
}
.detailsLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 6.2rem;
}
.detailsLeftContainer p {
  color: #4b5054;
  font-family: "Lexend Deca";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.detailsLeftContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 6.2rem;
  gap: 6rem;
}
.detailsLeftContainer3 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 6.2rem;
  gap: 7.5rem;
}
.detailsLeftContainer2 p {
  color: #4b5054;
  font-family: "Lexend Deca";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.detailsLeftContainer3 p {
  color: #4b5054;
  font-family: "Lexend Deca";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.sport_new_input{
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: transparent;
}

.sport_box{
  position: absolute;
  top: 80%;
  left: 0;
  border: 1px solid #ccc;
  margin-top: 5px;
  background-color: white;
  z-index: 1;
  border-radius: 5px;
  width: 100%;
  max-height: 12rem;
  overflow: auto;
}

.detailsRightContainer {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}
.detailsRightContainer p {
  color: #1e2224;
  font-family: "Lexend Deca";
  font-size: 0.78rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  margin-bottom:0.5rem;
}
.detailsStatus {
  border-radius: 0.1875rem;
  width: fit-content;
  background: #63c257;
  text-align: center;
  padding: 0.1rem 0.8rem;
}
.detailsStatus span {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 0.75rem;
  font-weight: 400;
}
.modalLeftBtnBox {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.modalLeftBtnBox2 {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.convertToDeal {
  padding: 8px 20px;
  font-family: "Lexend Deca", sans-serif;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  border: 1px solid #077838;
  background: #077838;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}
.convertToDeal:hover {
  cursor: pointer;
  color: #077838;
  background: #ffffff;
  border: 1px solid #077838;
  transition: all 400ms ease-in-out;
}
.disabledBtn {
  padding: 8px 20px;
  font-family: "Lexend Deca", sans-serif;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  border: 1px solid #f3f6fa;
  background: #f3f6fa;
  color: #909daf;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}

.selected {
  background-color: #eff6ff;
}

.detailsRightContainer select {
  width: 100%;
  color: #1e2224;
  font-weight: 400;
  border: 1px solid #dcdcdc;
  outline: rgb(59, 59, 59);
  background-color: #ffffff;
  font-size: 0.8rem;
  font-family: "Lexend Deca";
  border-radius: 0.3125rem;
  padding: 0.3rem;
}
.detailsRightContainer select:focus {
  border: 1px solid #e2e9f2;
  box-shadow: none;
}
.detailsRightContainer select:hover {
  background-color: rgb(227, 225, 225);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.updateMsg {
  color: #077838;
  font-size: 15px;
  font-family: "Lexend Deca";
  text-transform: capitalize;
  min-width: 150px;
  font-weight: 600;
  text-align: center;
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* tabination part */
.tab-navigation {
  display: flex;
  padding: 0.7rem;
  gap: 0.5rem;
  flex-wrap: nowrap;
  /* justify-content: space-between; */
}

.tab-navigation button {
  padding: 8px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 0.3125rem;
  font-family: "Lexend Deca";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #818181;
  background: #fff;
  cursor: pointer;
  margin-right: 0rem;
}
.tab-navigation button .fa-sharp {
  margin-right: 0.5rem;
}
.tab-navigation button.active {
  border: 1px solid #2b74da;
  color: #2b74da;
  border-radius: 0.3125rem;
  font-family: "Lexend Deca";
  /* font-size: 0.875rem; */
  font-style: normal;
  /* font-weight: 400; */
  line-height: normal;
  background: #fff;
  cursor: pointer;
}
.user-details--right {
  width: 100%;
}
.tab-content {
  height: 90vh;
  overflow-y: scroll;
  margin-top: 8px;
}
.tab_content_new {
  height: 80vh;
  overflow-y: scroll;
}

.email-tab-content,
.activity-tab-content,
.notes-tab-content {
  margin: 0rem 0.7rem;
}

.notesEditor {
  /* border: 2px solid aqua; */
  height: 13rem;
  padding-right: 0.6rem;
}
.addNoteBtn {
  padding-right: 0.6rem;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.savedNotes {
  max-height: 70vh;
  overflow-y: scroll;
  padding-bottom: 0.5rem;
}
.addNotesDropdown {
  /* border:2px solid red; */
  /* margin-right: 0.3rem; */
  border: 1px solid #dcdcdc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem;
  text-transform: capitalize;
  color: #385083;
  background-color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
}

.answer {
  display: none;
}
.display_answer {
  display: block;
}
.deleteNote {
  display: flex;
  justify-content: flex-end;
}
.noteDeleteBtn {
  cursor: pointer;
  background: #fff;
  border: none;
}
.colapedEditor {
  cursor: pointer;
  /* margin-right: 0.3rem; */
  height: 2.625rem;
  border-radius: 0.3125rem;
  background: #f4f5f9;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 16px;
}
.colapedEditor p {
  color: #909db0;
  font-family: Lexend Deca;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
}

.note-display {
  background-color: #fff;
  border-radius: 0.3125rem;
  border: 1px solid var(--stroke, #dcdcdc);
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}

.notes-main {
  width: 100%;
  margin-right: 1rem;
}

.note-content {
  display: flex;
  align-items: start;
  justify-content: start;
}

.notes-by {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notes-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notes-date p {
  padding-right: 0.75rem;
}

.arrow-greater {
  margin-left: 1rem;
  margin-top: 0.6rem;
  margin-right: 0.5rem;
}

.notes-by {
  margin-top: 0.5rem;
}

.notes-by p {
  color: #1e2224;
  font-family: "Lexend Deca", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}

.notes-by span {
  font-weight: 600;
}

.notes-date p {
  color: #33475b;
  font-size: 0.75rem;
}

.three-side-dots {
  /* width: 0.875rem; */
  /* height: 0.875rem; */
  /* flex-shrink: 0; */
  display: flex;
  gap: 0.5rem;
}

.note-content {
  width: 100%;
}

.notes-content p {
  color: #1e2224;
  font-family: "Lexend Deca", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
  margin-top: 0.6rem;
  padding-bottom: 0.6rem;
  text-align: left;
}
.formEditor2 {
  height: 13rem;
}

.formEditor2 button {
  background-color: black;
}

.note-discard-btn {
  padding: 8px 20px;
  font-family: "Lexend Deca", sans-serif;
  border-radius: 0.3125rem;
  background: white;
  color: #1e2224;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
  border-radius: 0.3125rem;
  border: 1px solid #dcdcdc;
  margin-right: 0.5rem;
}

.note-discard-btn:hover {
  cursor: pointer;
  color: white;
  background: rgb(204, 47, 47);
  border: 1px solid rgb(204, 47, 47);
  transition: all 400ms ease-in-out;
}

.note-save-btn {
  padding: 8px 20px;
  font-family: "Lexend Deca", sans-serif;
  border-radius: 0.3125rem;
  border: 1px solid #077838;
  background: #077838;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}

.note-save-btn:hover {
  cursor: pointer;
  color: #077838;
  background: #ffffff;
  border: 1px solid #077838;
  transition: all 400ms ease-in-out;
}
.ql-editor p {
  color: #1e2224;
  font-family: Lexend Deca;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
}
.quill {
  border-radius: 0.3125rem;
  background: #f4f5f9;
}
.ql-container {
  background: #f4f5f9;
}

#priority.disabled,
#status.disabled,
#label_id.disabled {
  color: white !important;
  width: max-content !important;
}

#lp-main-owner.disabled {
  color: #1e2224 !important;
  font-weight: 600 !important;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  font-family: "Lexend Deca";
  z-index: 10;
}

.popup {
  background-color: #fff;
  padding: 23px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 28.125rem;
  height: 12.6875rem;
  border-radius: 0rem 0rem 0.3125rem 0.3125rem;
  text-align: left;
  z-index: 99;
}
.popup > .popupHead {
  color: #1e2224;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}
.popup p {
  color: #33475b;
  font-family: Lexend Deca;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  margin-bottom: 10px;
}
.popup .deleteMsg {
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.popup-buttons {
  display: flex;
  justify-content: flex-end;
}

.popup-buttons button {
  margin: 0 8px;
  padding: 7px 15px;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.confirmBtn {
  background-color: #d83c38; /* Red color for "Yes" button */
  color: #fff;
  border: none;
}

.cancelBtn {
  border: 1px solid #dcdcdc;
  background: #fff;
}

.clouserDate {
  color: #f14949;
  font-family: "Lexend Deca";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: capitalize;
}
.DealName {
  color: #1e2224;
  font-family: "Lexend Deca";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02563rem;
  text-transform: uppercase;
}

.DealpriorityBox {
  border: 1px solid black;
}
.DealCard-rightBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1rem; */
}
.notes_flex{
  align-items: end;
  gap:0.5rem;
}

.notes_hover{
  position: relative;
}

.message-box {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 15rem;
  right:0;
  z-index: 100;
}

.notes_hover:hover .message-box {
  display: block;
}

.deal-sort {
  cursor: pointer;
}

.deal-sort img {
  width: 1.3rem;
  height: 1.3rem;
}

.note-btn{
  margin-left: 1rem;
}

.lead-nav-select{
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  outline: none;
  padding:0.2rem 0.2rem;
  padding-left: 0;
  border-radius: 0.3125rem;
  height: 2.00390625rem;
  margin-left: -1rem;
}

.owner-menu{
  left:0%;
  max-height: 70vh;
  overflow: scroll;
}

.lead-address-result{
  position: absolute;
  z-index: 1;
}

.lead-new-fix{
  position: relative;
}
.lead-address-edit-result{
  z-index: 100;
  position: absolute;
  top:120%;
  left:-48%;
  overflow-x: scroll;
  width: 250px;
}


.lead-pin-input{
  text-transform: uppercase;

}

.lead-new-position{
  position: relative;
}

#sports_lead, #city_lead, #distance_lead{
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  color: rgb(30, 34, 36);
  font-weight: 500;
  border: 1px solid #dcdcdc;
  width: 6rem;
  margin-left: 0.3rem;
}
.margin-left{
  margin-left: 1rem;
}
.greenVerified{
  min-width: 1.5rem;
  height: 1rem;
  background-color: #0bfa73;
  border-radius: 5px;
}
.newData{
  padding: 0.1rem 0.3rem;
  background-color: #fe5c4d;
  border-radius: 5px;
  color: white;
}
.labelData{
  padding: 0.1rem 0.3rem;
  background-color: #90e0ef;
  border-radius: 5px;
  color: white;
}
.yellowLogIn{
  min-width: 1.5rem;
  height: 1rem;
  background-color: #ffe246;
  border-radius: 5px;
}

.new_preview_flex{
  display:flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  gap:1rem;
}

.new_btnflex{
  display: flex;
  justify-content: end;
  align-items: center;
  gap:1rem;
}

.new_preview_flex img{
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.archive_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:1rem;
}

.btn-verified{
  color:#fff;
  white-space: nowrap;
  background-color: #077838;
  border-radius: 0.5rem;
  padding: 0.3rem;
  border: none;
  outline: none;
  font-size: 0.8rem;
}
.btn-unverified{
  color:#fff;
  white-space: nowrap;
  background-color: #f14949;
  border-radius: 0.5rem;
  padding: 0.3rem;
  border: none;
  outline: none;
  font-size: 0.8rem;
}
