.restore-comment {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.selected-comment {
    color: #33475B;
    font-size: 0.75rem;
    /* margin-bottom: 1rem; */
}
.box-border{
margin: 1rem 1.75rem;
padding: 1rem;
border-radius: 0.3125rem;
border: 1px solid #DCDCDC;
/* background: var(--White, #FFF); */
}
.comment-head{    
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.review-rating{
    margin: 0 0.5rem;
    font-size: 0.875rem;
    font-size: 0.875rem;
    padding: 0 0.5rem;
    border-radius: 3.125rem;
background: #F3F6FA;
}
.comment-save{
    margin-left: 0.5rem;
}
.redAlert{
    color: rgb(190, 4, 4);
}
.batchErrorLable{
    display: flex;
    justify-content: space-between;
}
.replysContainer{
    height: 8rem;
    overflow-y: scroll;
}

.replysContainer.expanded {
    height: 15rem;
}

.replysContainer.collapsed {
    height: 8rem;
}
.replyName{
    /* border: 2px solid red; */
    border-left: 1px solid #DCDCDC;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    padding: 0.5rem;
}
.reply-head{
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.bmpTab2ImgSection{
    margin-left: 1rem;
}
/* Style for the autocomplete dropdown */
.relativeInput{
    position: relative;
}
.autocomplete-dropdown {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 1000;
  }
  
  /* Style for each suggestion item in the dropdown */
  .autocomplete-dropdown div {
    padding: 10px;
    cursor: pointer;
  }
  .autocomplete-dropdown li {
    padding: 10px;
    cursor: pointer;
  }
  /* Highlight the suggestion item on hover */
  .autocomplete-dropdown div:hover {
    background-color: #f2f2f2;
  }

  .input_flex{
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: center;
  }
  
  .input_label {
    margin-top: 1.5rem;
  }
  
  .field_width{
    width: 30%;
  }
  
  .input_new_box{
    width: 100%;
  }
  
  .form-group{
    min-height: 44px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding-bottom: 0.5rem;
  }
  
  .form-group:focus-within,
  .form-input:focus{
    border-color: #007bff;
  }
  
  .gender_heading {
    left: 10px;
    padding: 0 5px;
    pointer-events: none;
    background-color: #fff;
    position: absolute;
    top: -15px;
    color: #007bff;
    font-size: 12px;
  }
  
  .form-group-radio {
    max-width: 45vw;
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 12px;
  }
  .radio-inline{     
    color: #4b5054;
    font-family: "Lexend Deca";
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.02563rem;
    margin-right: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
  }
  .radio-inline2{     
    color: #4b5054;
    font-family: "Lexend Deca";
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.02563rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
  }

  .check_input_2{
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
  }

  .mail img.img_size{
    width: 1rem;
    height: 1rem;
  }
  .mail img.img_size_1{
    width: 1.2rem;
    height: 1.2rem;
  }

  .assign-search{
    width: 27rem;
    padding: 0.94rem 1rem;
    padding-right: 2.5rem;
    height: 2rem;
    border-radius: 0.3125rem;
    outline: none;
    border: 1px solid #DCDCDC;
    background: #FFF;
  }
  .academy-card{
    cursor: pointer;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 0.3125rem;
    /* width: 100%; */
    margin: 8px 0px;
  }
.new-info-card{
  height: 98vh;
  overflow-y: scroll;
}
  .greenText{
    color: #2b74da;
  }
  .assignText{
    color: #11ba5a;
  }
  .check_url_box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
  .underline_status{
    text-decoration: underline;
   text-underline-offset: 0.2rem;
   text-decoration-color: red;
  }

  .otp_table{
    margin: 1rem;
    height: 65vh;
    overflow: auto;
  }
  
  .otp_table th{
    text-align: left;
  }
  .otp_table th:nth-child(1){
    width: 5rem;
   }
  .otp_table th:nth-child(2){
   width: 7rem;
  }
  .otp_table th:nth-child(3){
    width: 10rem;
   }
  .otp_table th:nth-child(4){
    width: 10rem;
   }
   .otp_desc {
    height: 1.4rem;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 55rem;
}
.info_table{
  margin: 1rem;
  height: 65vh;
  overflow: auto;
}

.info_table th{
  text-align: left;
}
.info_table th:nth-child(1){
  width: 5rem;
 }
.info_table th:nth-child(2){
 width: 13rem;
}
.info_table th:nth-child(3){
  width: 13rem;
 }
.info_table th:nth-child(4){
  width: 18rem;
 }
 .info_table th:nth-child(5){
  width: 18rem;
 }
 .heightFields{
  display: flex;
  justify-content: center;
  align-items: center;

 }
 .playerHeight{
  margin-right: 1rem;
  margin-left: 1rem;
 }